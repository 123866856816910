const learnMoreSectionData = {
  label: "LOREM IPSUM DOLOR",
  title: "Lorem Ipsum Dolor Sit Ame",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae justo nisl. Praesent cursus neque ins.",
  buttonText: "Learn More",
  rightImage: require("../../assets/mobiles.png"),
}

export default learnMoreSectionData
