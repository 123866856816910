import React from "react"
import Button from "../common/Button"
import "./index.scss"

const ConnectionsBanner = ({ label, title, image, connectionBannerData }) => {
  return (
    <section className="connection-banner section-margin bg-lightBlue">
      <div className="connection-banner-container section-container container-padding flex justify-between items-end md:items-center">
        <div className="content">
          <h2 className="label text-white font-semibold">{label}</h2>
          <h1 className="title text-white font-bold">{title}</h1>
          <div className="sm:flex items-center buttons">
            {connectionBannerData.buttons.map(({ text, label, Icon }) => {
              return (
                <Button
                  isBlack
                  className="mb-5 sm:mb-0 flex justify-center items-center mr-5"
                  key={text}
                >
                  <Icon />
                  <p className="ml-3 flex flex-col items-start">
                    <span className="btn-label text-white">{label}</span>
                    <span className="btn-title font-semibold"> {text} </span>
                  </p>
                </Button>
              )
            })}
          </div>
        </div>
        <div className="banner-image">
          <img src={image?.fluid?.src} alt="" />
        </div>
      </div>
    </section>
  )
}

export default ConnectionsBanner
