import React from "react"
import { AppleIcon, PlayStoreIcon } from "../../components/common/Icons"

const connectionBannerData = {
  label: "GET THE APP NOW",
  title: "Make Connections and Build Your Network",
  buttons: [
    {
      text: "App Store",
      label: "Download on the",
      Icon: AppleIcon,
    },
    { text: "Google Play", label: "Get it on", Icon: PlayStoreIcon },
  ],
  bannerImage: require("../../assets/connection-banner-mobile.png"),
}

export default connectionBannerData
