import React, { useRef } from "react"
import Swiper from "react-id-swiper"
import { StarIcon } from "../common/Icons"
import "./index.scss"

const ReviewsSlider = ({ reviews, nextIcon, prevIcon }) => {
  const swiperRef = useRef(null)
  const params = {
    slidesPerView: 2,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  }

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  return (
    <div className="slider-container">
      <Swiper {...params} ref={swiperRef}>
        {reviews.map(
          ({
            review: { review },
            customerName,
            jobTitle,
            customerAvatar,
            occupation,
            ratings,
          }) => {
            return (
              <div className="review-card-container" key={jobTitle}>
                <div className="review-card">
                  <div className="reviewer-avatar">
                    <img src={customerAvatar.fluid.src} alt="" />
                  </div>
                  <p className="review">{review}</p>
                  <hr />
                  <div className="bottom-content md:flex justify-between">
                    <div className="reviewer-description">
                      <h2 className="reviewer-name font-bold">
                        {customerName}
                      </h2>
                      <p className="job-description">
                        {jobTitle}, {occupation}
                      </p>
                    </div>
                    <div className="rating flex">
                      {Array.from({ length: 5 }).map((__, index) => (
                        <StarIcon
                          fill={ratings >= index + 1 ? "#ffd700" : "#222222"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        )}
      </Swiper>
      <button className="prev-btn" onClick={goPrev}>
        <img src={prevIcon} alt="" />
      </button>
      <button className="next-btn" onClick={goNext}>
        <img src={nextIcon} alt="" />
      </button>
    </div>
  )
}

const Reviews = ({
  title,
  titleBgImage,
  customerReviews,
  reviewsSectionData,
}) => {
  return (
    <section className="reviews-section section-container section-margin container-padding">
      <div className="reviews-heading">
        <h1 className="title font-bold">{title}</h1>
        <img src={titleBgImage.fluid} alt="" />
      </div>
      <div className="reviews-slider-container">
        <ReviewsSlider
          nextIcon={reviewsSectionData.nextIcon.default}
          prevIcon={reviewsSectionData.prevIcon.default}
          reviews={customerReviews}
        />
      </div>
    </section>
  )
}

export default Reviews
