import Img from "gatsby-image"
import React from "react"
import Button from "../common/Button"
import "./index.scss"

const LearnMore = ({ label, title, description, btnText, image }) => {
  return (
    <section className="learn-more-section grid items-center section-margin section-container container-padding">
      <div className="learn-more-content">
        <h2 className="label font-medium">{label}</h2>
        <h1 className="title font-bold">{title}</h1>
        <p className="description">{description}</p>
        <Button>{btnText}</Button>
      </div>
      <div className="learn-more-image">
        <Img fluid={image?.fluid} alt="" />
      </div>
    </section>
  )
}

export default LearnMore
