import { graphql } from "gatsby"
import * as React from "react"
import Header from "../components/common/Header"
import ConnectionsBanner from "../components/ConnectionsBanner"
import Features from "../components/Features"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import LearnMore from "../components/LearnMore"
import Reviews from "../components/Reviews"
import SEO from "../components/seo"
import connectionBannerData from "../site-data/connection-banner"
import learnMoreSectionData from "../site-data/learn-more"
import reviewsSectionData from "../site-data/reviews"

export const landingPageQuery = graphql`
  query {
    allContentfulLandingPage {
      edges {
        node {
          heroSectionTitle
          featureSectionTitle
          learnMoreSectionLabel
          learnMoreSectionTitle
          reviewsSectionTitle
          learnMoreSectionBtnText
          connectionsSectionLabel
          connectionsSectionTitle
          reviewsSectionTitleBgImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          heroSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          heroSectionContentBoxes {
            title
            image {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            description {
              description
            }
          }
          featuresSectionContentBoxes {
            title
            image {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            description {
              description
            }
          }
          learnMoreSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          reviews {
            review {
              review
            }
            customerName
            jobTitle
            occupation
            ratings
            customerAvatar {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
          connectionsSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          heroSectionDescription {
            heroSectionDescription
          }
          learnMoreSectionDescription {
            learnMoreSectionDescription
          }
          featureSectionDescription {
            featureSectionDescription
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const {
    allContentfulLandingPage: {
      edges: [
        {
          node: {
            heroSectionTitle,
            heroSectionContentBoxes,
            heroSectionDescription,
            heroSectionImage,
            featureSectionTitle,
            featureSectionDescription,
            featuresSectionContentBoxes,
            learnMoreSectionLabel,
            learnMoreSectionTitle,
            reviewsSectionTitle,
            learnMoreSectionImage,
            learnMoreSectionBtnText,
            connectionsSectionLabel,
            connectionsSectionTitle,
            reviews,
            learnMoreSectionDescription,
            connectionsSectionImage,
            reviewsSectionTitleBgImage,
          },
        },
      ],
    },
  } = data

  return (
    <Layout>
      <SEO title="Home" />
      <div className="top-section">
        <Header />
        <Hero
          title={heroSectionTitle}
          description={heroSectionDescription?.heroSectionDescription}
          image={heroSectionImage}
          contentBoxes={heroSectionContentBoxes}
        />
      </div>
      <Features
        title={featureSectionTitle}
        description={featureSectionDescription?.featureSectionDescription}
        contentBoxes={featuresSectionContentBoxes}
      />
      <LearnMore
        label={learnMoreSectionLabel}
        title={learnMoreSectionTitle}
        description={learnMoreSectionDescription?.learnMoreSectionDescription}
        btnText={learnMoreSectionBtnText}
        image={learnMoreSectionImage}
        learnMoreSectionData={learnMoreSectionData}
      />
      <Reviews
        title={reviewsSectionTitle}
        customerReviews={reviews}
        titleBgImage={reviewsSectionTitleBgImage}
        reviewsSectionData={reviewsSectionData}
      />
      <ConnectionsBanner
        label={connectionsSectionLabel}
        title={connectionsSectionTitle}
        image={connectionsSectionImage}
        connectionBannerData={connectionBannerData}
      />
    </Layout>
  )
}

export default IndexPage
